<template>
  <a-tabs v-model:activeKey="activeKey">
    <a-tab-pane key="0" tab="推送给用户">
      <table-page :config="config" />
    </a-tab-pane>
    <a-tab-pane key="1" tab="推送给机构">
      <table-page :config="configRecommend" />
    </a-tab-pane>
  </a-tabs>
</template>

<script>
import TablePage from "@/components/TablePage";
import { msgType, msgStatus } from '@/utils/public';

export default {
  name: 'strategyInfoDetail',
  components: { TablePage },
  props: ['selectedRows'],
  data() {
    return {
      activeKey: '0',
      config: {
        dataUrl: `/message/manualPush/list?pushTo=userPush`,
        filter: {
          controls: [
            {
              key: 'title',
              label: '消息标题',
              type: 'text'
            },
            {
              key: 'msgType',
              label: '消息类型',
              type: 'select',
              config: {
                options: msgType
              }
            },
            {
              key: 'status',
              label: '状态',
              type: 'select',
              config: {
                options: msgStatus
              }
            },
          ]
        },
        columns: [
          {
            key: 'title',
            title: '消息标题',
            width: '150px',
          },
          {
            key: 'msgType',
            title: '消息类型',
            width: '100px',
            type: 'textCustom',
            config: {
              text: (val) => {
                return val == 'push' ? 'push' : val == 'website' ? '站内信' : ''
              }
            }
          },
          {
            key: 'userType',
            title: '推送用户',
            width: '100px',
            type: 'textCustom',
            config: {
              text: (val) => {
                return val == 'allUser' ? '全部用户' : val == 'assignUser' ? '指定用户' : ''
              }
            }
          },
          {
            key: 'status',
            title: '状态',
            width: '100px',
            type: 'textCustom',
            config: {
              text: (val) => {
                return val == 0 ? '未推送' : val == 1 ? '已推送' : ''
              }
            }
          },
          {
            key: 'pushTime',
            title: '推送时间',
            width: '130px',
            type: 'date'
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '166px'
          }
        ],
        /* 表格操作 */
        actions: [
          {
            key: 'detail',
            text: '新建推送',
            type: 'blank',
            permission: '/message/manualPush/add',
            config: {
              title: '新建推送',
              color: 'primary',
              component: () => import("./addPushUsers")
            }
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
          {
            key: 'add',
            text: '发送测试',
            icon: 'plus',
            type: 'form',
            permission: '/message/manualPush/sendMessageTest',
            disabled: ({ selectedRows }) => {
              if(selectedRows[0].fixedTimePush == null) {
                return selectedRows[0].status == 0 ? false : true;
              } else {
                return selectedRows[0].status == 0 ? false : true
              }
            },
            config: {
              color: 'primary',
              title: '发送测试',
              submitUrl: '/message/manualPush/sendMessageTest',
              submitText: '确定',
              controls: [
                {
                  key: 'pushTo',
                  label: '推送类型',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: 'userPush',
                  config: {
                    options: [
                      {
                        value: 'userPush',
                        text: '推送给用户',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'uidList',
                  label: '测试推送UID',
                  type: 'text',
                  required: true,
                  tips: '有多个UID时，用英文,号分开',
                  config: {
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'lock',
            text: '推送',
            type: 'confirm',
            permission: '/message/manualPush/sendMessage',
            disabled: ({ selectedRows }) => {
              if(selectedRows[0].fixedTimePush == null) {
                return selectedRows[0].status == 0 ? true : false;
              } else {
                return false
              }
            },
            config: {
              color: 'primary',
              submitColor: 'primary',
              submitUrl: '/message/manualPush/sendMessage',
              otherKey: 'pushTo',
              otherValue: 'userPush'
            }
          },

          {
            key: 'lock',
            text: '删除',
            type: 'confirm',
            permission: '/message/manualPush/delete',
            disabled: ({ selectedRows }) => {
              if(selectedRows[0].fixedTimePush == null) {
                return selectedRows[0].status == 0 ? true : false;
              } else {
                return selectedRows[0].status == 0 ? true : false;
              }
            },
            config: {
              color: 'primary',
              submitColor: 'primary',
              submitUrl: '/message/manualPush/delete'
            }
          },
          {
            key: 'detail',
            text: '查看',
            type: 'blank',
            permission: '/message/manualPush/getDetailById',
            disabled: ({ selectedRows }) => {
              if(selectedRows[0].fixedTimePush == null) {
                return false
              } else {
                return selectedRows[0].status == 0 ? true : false;
              }
            },
            config: {
              title: '查看',
              component: () => import("./listDetail")
            }
          },
          {
            key: 'detail',
            text: '编辑',
            type: 'blank',
            permission: '/message/manualPush/edit',
            disabled: ({ selectedRows }) => {
              if(selectedRows[0].fixedTimePush == null) {
                return selectedRows[0].status == 0 ? false : true;
              } else {
                return selectedRows[0].status == 0 ? false : true;
              }
            },
            config: {
              title: '编辑',
              component: () => import("./editPushUsers")
            }
          },
        ]
      },
      configRecommend: {
        checkbox: true,
        dataUrl: `/message/manualPush/list?pushTo=orgPush`,
        filter: {
          controls: [
            {
              key: 'title',
              label: '消息标题',
              type: 'text'
            },
            {
              key: 'msgType',
              label: '消息类型',
              type: 'select',
              config: {
                options: msgType
              }
            },
            {
              key: 'status',
              label: '状态',
              type: 'select',
              config: {
                options: msgStatus
              }
            },
          ]
        },
        columns: [
          {
            key: 'title',
            title: '消息标题',
            width: '150px',
          },
          {
            key: 'msgType',
            title: '消息类型',
            width: '100px',
            type: 'textCustom',
            config: {
              text: (val) => {
                return val == 'push' ? 'push' : val == 'website' ? '站内信' : ''
              }
            }
          },
          {
            key: 'orgType',
            title: '推送机构',
            width: '100px',
            type: 'textCustom',
            config: {
              text: (val) => {
                return val == 'allOrg' ? '全部机构' : val == 'assignOrg' ? '指定机构' : ''
              }
            }
          },
          {
            key: 'status',
            title: '状态',
            width: '100px',
            type: 'textCustom',
            config: {
              text: (val) => {
                return val == 0 ? '未推送' : val == 1 ? '已推送' : ''
              }
            }
          },
          {
            key: 'pushTime',
            title: '推送时间',
            width: '130px',
            type: 'date'
          },
          {
            key: 'name',
            title: '操作',
            type: 'actions',
            fixed: 'right',
            width: document.documentElement.clientWidth <= 768 ? '70px' : '166px'
          }
        ],
        /* 表格操作 */
        actions: [
        {
            key: 'detail',
            text: '新建推送',
            type: 'blank',
            permission: '/message/manualPush/add',
            config: {
              title: '新建推送',
              color: 'primary',
              component: () => import("./addPushOrg")
            }
          },
        ],
        /* 表格排序 */
        sorter: {
          /* 可排序列 */
          sortableColumns: [''],
          /* 默认排序 */
          defaultSort: ''
        },
        rowActions: [
        {
            key: 'add',
            text: '发送测试',
            icon: 'plus',
            type: 'form',
            permission: '/message/manualPush/sendMessageTest',
            disabled: ({ selectedRows }) => {
              if(selectedRows[0].fixedTimePush == null) {
                return selectedRows[0].status == 0 ? false : true;
              } else {
                return selectedRows[0].status == 0 ? false : true
              }
            },
            config: {
              color: 'primary',
              title: '发送测试',
              submitUrl: '/message/manualPush/sendMessageTest',
              submitText: '确定',
              controls: [
                {
                  key: 'pushTo',
                  label: '推送类型',
                  type: 'select',
                  required: true,
                  disabled: true,
                  defaultValue: 'orgPush',
                  config: {
                    options: [
                      {
                        value: 'orgPush',
                        text: '推送给机构',
                      }
                    ]
                  }
                },
                {},
                {
                  key: 'orgNameList',
                  label: '测试推送机构名字',
                  type: 'text',
                  required: true,
                  tips: '有多个机构时，用英文,号分开',
                  config: {
                  }
                },
                {}
              ]
            }
          },
          {
            key: 'lock',
            text: '推送',
            type: 'confirm',
            permission: '/message/manualPush/sendMessage',
            disabled: ({ selectedRows }) => {
              if(selectedRows[0].fixedTimePush == null) {
                return selectedRows[0].status == 0 ? true : false;
              } else {
                return false
              }
            },
            config: {
              color: 'primary',
              submitColor: 'primary',
              submitUrl: '/message/manualPush/sendMessage',
              otherKey: 'pushTo',
              otherValue: 'orgPush'
            }
          },
          {
            key: 'lock',
            text: '删除',
            type: 'confirm',
            permission: '/message/manualPush/delete',
            disabled: ({ selectedRows }) => {
              if(selectedRows[0].fixedTimePush == null) {
                return selectedRows[0].status == 0 ? true : false;
              } else {
                return selectedRows[0].status == 0 ? true : false;
              }
            },
            config: {
              color: 'primary',
              submitColor: 'primary',
              submitUrl: '/message/manualPush/delete'
            }
          },
          {
            key: 'detail',
            text: '查看',
            type: 'blank',
            permission: '/message/manualPush/getDetailById',
            disabled: ({ selectedRows }) => {
              if(selectedRows[0].fixedTimePush == null) {
                return false
              } else {
                return selectedRows[0].status == 0 ? true : false;
              }
            },
            config: {
              title: '查看',
              component: () => import("./listDetail")
            }
          },
          {
            key: 'detail',
            text: '编辑',
            type: 'blank',
            permission: '/message/manualPush/edit',
            disabled: ({ selectedRows }) => {
              if(selectedRows[0].fixedTimePush == null) {
                return selectedRows[0].status == 0 ? false : true;
              } else {
                return selectedRows[0].status == 0 ? false : true;
              }
            },
            config: {
              title: '编辑',
              component: () => import("./editPushOrg")
            }
          },
        ]
      },
    };
  }

};
</script>

<style lang="less" scoped></style>